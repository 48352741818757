import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer'
  },
  {
    name: 'Users Management',
    url: '/users',
    icon: 'fa fa-users',
    children: [
      {
        name: 'All Users',
        url: '/user-management',
        icon: 'fa fa-user'
      },
      {
        name: 'Registered Users',
        url: '/registered-user',
        icon: 'fa fa-thin fa-registered',
      },
      {
        name: 'Approved Users',
        url: '/verified-user',
        icon: 'fa fa-thumbs-up',
      },
      {
        name: 'Rejected Users',
        url: '/rejected-user',
        icon: 'fa fa-thumbs-down',
      },
      {
        name: 'KYC Pending User',
        url: '/pending-verification',
        icon: 'fa fa-clock-o',
      },
      {
        name: 'Pending Admin Approval',
        url: '/pending-verification-admin',
        icon: 'fa fa-clock-o',
      },
      {
        name: 'KYC Submitted',
        url: '/kyc-submitted',
        icon: 'fa fa-check',
      },
      {
        name: 'Referral Users',
        url: '/referral-user',
        icon: 'fa fa-recycle',
      },
      {
        name: 'Restricted Users',
        url: '/suspended-user',
        icon: 'fa fa-pause',
      },
     
      // {
      //   name: 'POI Users List',
      //   url: '/poi-user-list',
      //   icon: 'fa fa-id-card',
      // },
      // {
      //   name: 'POA Users List',
      //   url: '/poa-user-list',
      //   icon: 'fa fa-solid fa-location-arrow',
      // },
      // {
      //   name: 'AML Users List',
      //   url: '/aml-user-list',
      //   icon: 'fa fa-money',
      // },
      {
        name: 'Manual AML Scan',
        url: '/manual-aml-scan',
        icon: 'fa fa-money',
      },
    ]
  },
  {
    name: 'Notifications',
    url: '/notification',
    icon: 'fa fa-bell',
    children: [
      {
        name: 'Send Email',
        url: '/send-email',
        icon: 'fa fa-envelope'
      },
      {
        name: 'Send Bulk Email',
        url: '/send-bulk-email',
        icon: 'fa fa-envelope-open',
     },
      {
        name: 'Send SMS',
        url: '/send-sms',
        icon: 'fa fa-commenting',
      },
      {
        name: 'Send Bulk SMS',
        url: '/send-bulk-sms',
        icon: 'fa fa-comments',
      },
      {
        name: 'Announcement',
        url: '/announcement',
        icon: 'fa fa-bullhorn'
      }
    ]
  },
  {
    name: 'Reward Management',
    url: '/rewards',
    icon: 'fa fa-gift',
    children: [{
      name: 'Referral Reward',
      url: '/referral-reward',
      icon: 'fa fa-cubes'
    },
    {
      name: 'Welcome Reward',
      url: '/welcome-reward',
      icon: 'fa fa-handshake-o'
    }]
  },
  {
    name: 'Reporting',
    url: '/reporting',
    icon: 'fa fa-list-alt',
    children: [{
      name: 'Deposit Reports',
      url: '/reporting/deposit',
      icon: 'fa fa-file-text-o'
    },
    {
      name: 'Withdrawal Reports',
      url: '/reporting/withdrawal',
      icon: 'fa fa-money'
    },
    {
      name: 'Trade Reports',
      url: '/reporting/trade',
      icon: 'fa fa-solid fa-trademark'
    },
    {
      name: 'Wallet Addresses Listing',
      url: '/wallet-address',
      icon: 'fa fa-recycle'
    },  
    {
      name: 'Currency Wise Wallet Address Listing',
      url: '/currency-wallet-address',
      icon: 'fa fa-user'

    },     
  ]
  },
  {
    name: 'Fund Management',
    url: '/fund-management',
    icon: 'fa fa-usd'
  },
  {
    name: 'Crypto Management',
    url: '/crypto-management',
    icon: 'fa fa-btc',
    children: [
      {
        name: 'Add Coin/Token',
        url: '/crypto-management/add-coin',
        icon: 'fa fa-btc'
      } 
    ]
  },
  {
    name: 'Fee Management',
    url: '/fee',
    icon: 'fa fa-usd',
    children: [
      {
        name: 'Withdrawal Fees',
        url: '/withdrawal-fees',
        icon: 'fa fa-credit-card'
      },
      {
        name: 'Trading Fees',
        url: '/trading-fees',
        icon: 'fa fa-solid fa-trademark'
      },
    ]
  },
  {
    name: 'Revenue Reports',
    url: '/revenue',
    icon: 'fa fa-file-text',
    children: [
      {
        name: 'Withdrawal Revenue',
        url: '/withdrawal-revenue',
        icon: 'fa fa-file-archive-o'
      },  
      {
        name: 'Trade Revenue',
        url: '/trade-revenue',
        icon: 'fa fa-user'
      },     
    ]
  },
  {
    name: 'Initialization',
    url: '/init',
    icon: 'fa fa fa-rocket',
    children: [{
      name: 'Component Initialization',
      url: '/initialize-exchange',
      icon: 'fa fa-refresh'
    }]
  },
  {
    name: 'Restrictions',
    url: '/restrict',
    icon: 'fa fas fa-ban',
    children: [{
      name: 'Withdraw Restrictions',
      url: '/withdraw-restrictions',
      icon: 'fa fa-refresh'
    },
    {
      name: 'Trade Restrictions',
      url: '/trade-restriction',
      icon: 'fa fa-user'
    },
    {
      name: 'Country Restrictions',
      url: '/country-restrictions',
      icon: 'fa far fa-globe'
    },]
  },
];
