import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DataTablesModule } from 'angular-datatables';
import { ModalModule } from 'ngx-bootstrap/modal';
import { UserManagementService } from '../../shared/services/user-management.service';
import { PoaUserListComponent } from './poa-user-list.component';

const routes = [
  {
    path: '',
    data: {
      title: 'POA-User-List'
    },
    children: [
      {
        path: '',
        component: PoaUserListComponent,
        data: {
          title: 'POA-User-List'
        }
      },
    ]
  }
];


@NgModule({
  declarations: [
    PoaUserListComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    ModalModule.forRoot(),
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    DataTablesModule
  ],
  providers: [
    UserManagementService
  ]
})
export class PoaUserListModule { }
