import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DataTablesModule } from 'angular-datatables';
import { ModalModule } from 'ngx-bootstrap/modal';
import { UserManagementService } from '../../shared/services/user-management.service';
import { AmlUserListComponent } from './aml-user-list.component';
import { TabsModule } from 'ngx-bootstrap/tabs';

const routes = [
  {
    path: '',
    data: {
      title: 'AML-User-List'
    },
    children: [
      {
        path: '',
        component: AmlUserListComponent,
        data: {
          title: 'AML-User-List'
        }
      },
    ]
  }
];


@NgModule({
  declarations: [
    AmlUserListComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    ModalModule.forRoot(),
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    DataTablesModule,
    TabsModule
  ],
  providers: [
    UserManagementService
  ]
})
export class AmlUserListModule { }
