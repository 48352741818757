import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpResponse,
    HttpErrorResponse,
    HttpInterceptor
} from '@angular/common/http';
import { Router } from "@angular/router";
import { tap } from 'rxjs/operators';
import { Observable } from "rxjs";

import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(
        public router: Router,
        private spinner: NgxSpinnerService,
    ) { }

    intercept(
        req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.spinner.show();
        if (localStorage.getItem('tokenData')) {
            let tokenData = JSON.parse(localStorage.getItem('tokenData'));
            req = req.clone({
                setHeaders: {
                    "access-token": tokenData.access_token,
                }
            });
        }

        return next.handle(req).pipe(tap(
            (event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    this.spinner.hide();
                }
            },
            (err: any) => {
                this.spinner.hide();

                if (err.error.status == 402 || err.error.status == 0) {
                    // localStorage.removeItem('user_details');
                    this.router.navigate(['/login']);
                }
                if (err instanceof HttpErrorResponse) {
                    this.spinner.hide();
                }
            }
        ));
    }

}