import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';
import { LoginComponent } from './views/login/login.component';

import { UserAuth } from './shared/guards/userAuth';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    data: {
      title: 'Forgot Password Page'
    }
  },
  {
    path: 'reset-password/:resetCode',
    loadChildren: () => import('./views/reset-password/reset-password.module').then(m => m.ResetPasswordModule)
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    // canActivate: [UserAuth],
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'change-password',
        loadChildren: () => import('./views/change-password/change-password.module').then(m => m.ChangePasswordModule)
      },
      {
        path: 'user-management',
        loadChildren: () => import('./views/user-management/user-management.module').then(m => m.UserManagementModule)
      },
      {
        path: 'registered-user',
        loadChildren: () => import('./views/registered-user/registered-user.module').then(m => m.RegisteredUserManagementModule)
      },
      {
        path: 'verified-user',
        loadChildren: () => import('./views/verified-user/verified-user-management.module').then(m => m.VerifiedUserManagementModule)
      },
      {
        path: 'referral-user',
        loadChildren: () => import('./views/referral-user/referral-user.module').then(m => m.ReferralUserModule)
      },
      {
        path: 'suspended-user',
        loadChildren: () => import('./views/suspended-user/suspended-user.module').then(m => m.SuspendedUserModule)
      },
      {
        path: 'unsuspended-user',
        loadChildren: () => import('./views/unsuspended-user/unsuspended-user.module').then(m => m.UnsuspendedUserModule)
      },
      {
        path: 'rejected-user',
        loadChildren: () => import('./views/rejected-user/rejected-user.module').then(m => m.RejectedUserModule)
      },
      {
        path: 'pending-verification',
        loadChildren: () => import('./views/pending-verification/pending-verification.module').then(m => m.PendingVerificationModule)
      },
      {
        path: 'pending-verification-admin',
        loadChildren: () => import('./views/pending-verify-admin/pending-verify-admin.module').then(m => m.PendingVerifyAdminModule)
      },
      {
        path: 'kyc-submitted',
        loadChildren: () => import('./views/kyc-submitted/kyc-submitted.module').then(m => m.KycSubmittedModule)
      },
      {
        path: 'announcement',
        loadChildren: () => import('./views/announcement/announcement.module').then(m => m.AnnouncementModule)
      },
      {
        path: 'referral-reward',
        loadChildren: () => import('./views/referral-reward/referral-reward.module').then(m => m.ReferralRewardModule)
      },
      {
        path: 'welcome-reward',
        loadChildren: () => import('./views/welcome-reward/welcome-reward.module').then(m => m.WelcomeRewardModule)
      },
      {
        path: 'fund-management',
        loadChildren: () => import('./views/fund-management/fund-management.module').then(m => m.FundManagementModule)
      },
      {
        path: 'crypto-management',
        loadChildren: () => import('./views/crypto-management/crypto-management.module').then(m => m.CryptoManagementModule)
      },
      {
        path: 'send-email',
        loadChildren: () => import('./views/send-email/send-email.module').then(m => m.SendEmailModule)
      },
      {
        path: 'send-bulk-email',
        loadChildren: () => import('./views/send-bulk-email/send-bulk-email.module').then(m => m.SendBulkEmailModule)
      },
      {
        path: 'send-sms',
        loadChildren: () => import('./views/send-sms/send-sms.module').then(m => m.SendSmsModule)
      },
      {
        path: 'send-bulk-sms',
        loadChildren: () => import('./views/send-bulk-sms/send-bulk-sms.module').then(m => m.SendBulkSmsModule)
      },
      {
        path: 'reporting',
        loadChildren: () => import('./views/reporting/reporting.module').then(m => m.ReportingModule)
      },
      {
        path: 'withdrawal-fees',
        loadChildren: () => import('./views/withdrawal-fees/withdrawal-fees.module').then(m => m.WithdrawalFeesModule)
      },
      {
        path: 'trading-fees',
        loadChildren: () => import('./views/trading-fees/trading-fees.module').then(m => m.TradingFeesModule)
      },
      {
        path: 'withdrawal-revenue',
        loadChildren: () => import('./views/withdrawal-revenue/withdrawal-revenue.module').then(m => m.WithdrawalRevenueModule)
      },
      {
        path: 'trade-revenue',
        loadChildren: () => import('./views/trade-revenue/trade-revenue.module').then(m => m.TradeRevenueModule)
      },
      {
        path: 'initialize-exchange',
        loadChildren: () => import('./views/initialize-exchange/initialize-exchange.module').then(m => m.initializeExchangeModule)
      },
      {
        path: 'wallet-address',
        loadChildren: () => import('./views/wallet-address/wallet-address.module').then(m => m.WalletAddressModule)
      },
      {
        path: 'currency-wallet-address',
        loadChildren: () => import('./views/currency-wallet-address/currency-wallet-address.module').then(m => m.CurrencyWalletAddressModule)
      },
      {
        path: 'withdraw-restrictions',
        loadChildren: () => import('./views/withdraw-restrictions/withdraw-restrictions.module').then(m => m.WithdrawRestrictionsModule)
      },
      {
        path: 'trade-restriction',
        loadChildren: () => import('./views/trade-restriction/trade-restriction.module').then(m => m.TradeRestrictionModule)
      },
      {
        path: 'country-restrictions',
        loadChildren: () => import('./views/country-restrictions/country-restrictions.module').then(m => m.CountryRestrictionsModule)
      },
      {
        path: 'poi-user-list',
        loadChildren: () => import('./views/poi-user-list/poi-user-list.module').then(m => m.PoiUserListModule)
      },
      {
        path: 'poa-user-list',
        loadChildren: () => import('./views/poa-user-list/poa-user-list.module').then(m => m.PoaUserListModule)
      },
      {
        path: 'aml-user-list',
        loadChildren: () => import('./views/aml-user-list/aml-user-list.module').then(m => m.AmlUserListModule)
      },
      {
        path: 'manual-aml-scan',
        loadChildren: () => import('./views/manual-aml-scan/manual-aml-scan.module').then(m => m.ManualAmlScanModule)
      },
      {
        path: 'pending-poa-list',
        loadChildren: () => import('./views/pending-poa/pending-poa.module').then(m => m.PendingPOAModule)
      },
    ]
  },
  // { path: '**', component: P404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
