import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private isLoginSource = new BehaviorSubject(false);
  loginMessage = this.isLoginSource.asObservable();
  
  constructor() { }

  changeLoginStatus(message: boolean) {
    this.isLoginSource.next(message)
  }
}
