import { Component, OnInit } from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { data, event } from 'jquery';
import { UserManagementService } from '../../shared/services/user-management.service';
import { NotificationService } from '../../shared/services/toastr.service';
import {
  FormControl,
  FormGroup,
} from '@angular/forms';

@Component({
  selector: 'app-country-restrictions',
  templateUrl: './country-restrictions.component.html',
 
})
export class CountryRestrictionsComponent implements OnInit {
  DisabledCountryForm: any;
  event:any;
  AllCountry:Array<any>=[];
  DisabledCountry:Array<any>=[];
  Countryname:any;
  items : any;
  submitted: boolean = false;

  constructor(private userManagementService: UserManagementService,
    private toastr: NotificationService,) { }

  ngOnInit(): void {
    this.getDisabledCountryList();
    this.getCountryList();
    this.Formbuild();
    //this.drop(this.event);
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    }
  }

  Formbuild(){
    this.DisabledCountryForm = new FormGroup({
      disabledCountry: new FormControl('', [])
    });
  }

  getCountryList() {
    this.userManagementService.getCountryList()
      .subscribe(
        response => {
          response.result.forEach(country => {
            if(!this.DisabledCountry.includes(country.countryName)){
              this.AllCountry.push(country.countryName);
            }
          });
        },
        error => {
          this.toastr.showError(error.error.statusMsg, "");
        }
      );
  }

  getDisabledCountryList() {
    this.userManagementService.getDisabledCountryList()
      .subscribe(
        response => {
          this.DisabledCountry = response.result;
          this.DisabledCountry = response.result.map(ele => ele.disabledCountries);
        },
        error => {
          this.toastr.showError(error.error.statusMsg, "");
        }
    );
  }

  SubmitDisabledCountry() {
    this.DisabledCountry;
      this.userManagementService.setDisabledCountries(this.DisabledCountry).subscribe(suc => {
        if (suc) {
          this.DisabledCountry = this.DisabledCountry;
          this.toastr.showSuccess("Disabled Countries Added Successfully.", "");
        }
      }, err => {
        this.toastr.showError(err.error.statusMsg, "");
      });
   }
}


