import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { NotificationService } from '../../shared/services/toastr.service';
import { UserManagementService } from '../../shared/services/user-management.service';
import { TabsetComponent, TabDirective } from 'ngx-bootstrap/tabs';

@Component({
  selector: 'app-aml-user-list',
  templateUrl: './aml-user-list.component.html',
})
export class AmlUserListComponent implements OnInit {

  disableSwitching: boolean;
  @ViewChild('tabset') tabset: TabsetComponent;
  @ViewChild('first') first: TabDirective;
  @ViewChild('second') second: TabDirective;

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  userList: Array<any>;
  UserListAML: Array<any>=[];
  searchUserList: Array<any> = [];
  public modalRef: BsModalRef;
  disapproveUserForm: FormGroup;
  @ViewChild('disapproveUserModal') public disapproveUserModal: ModalDirective;
  @ViewChild('viewUserDetailsModal') public viewUserDetailsModal: ModalDirective;
  @ViewChild('Google2FAModel') public Google2FAModel: ModalDirective;
  isRejectingUser: boolean = false;
  disapproveUserFormSubmitted: boolean = false;
  allCountryList: any = environment.countries;
  countryList: any = [];
  userDetails: any = {};
  datauser: any = {};
  payloadData: any = {};
  userId: any = '';
  searchText: string = '';
  isSearch: boolean = false;
  styleSR: any = '';
  regulaFields: any;

  constructor( private toastr: NotificationService,
    private userManagementService: UserManagementService,
    private modalService: BsModalService,
    private _fb: FormBuilder,
    private router: Router) { }

  ngOnInit(): void {
    this.getUserList();
    this.disapproveUserForm = this._fb.group({
      rejectSubject: ["", Validators.required],
      rejectReason: ["", Validators.required]
    });
  }

  disapproveUserModalOpen(user: any) {
    this.disapproveUserFormSubmitted = false;
    this.disapproveUserForm.reset();
    this.userId = user;
    this.disapproveUserModal.show();
    this.disapproveUserSubmit()
  }

  disapproveUserModalClose() {
    this.disapproveUserModal.hide();
    this.disapproveUserForm.reset();
    this.disapproveUserFormSubmitted = false;
    this.Google2FAModel.hide();
  }

  // Delete User
  deleteUser(userId) {
    var r = confirm("Are you sure!");
    if (r == true) {
      this.userId = userId;
      this.payloadData = { userId: this.userId }
      this.userManagementService.deleteUser(this.payloadData).subscribe(suc => {
        if (suc) {
          this.toastr.showSuccess(suc['statusMsg'], "");
          this.rerender();
        }
      }, err => {
        this.toastr.showError(err.error.statusMsg, "");
      });
    }
  }

  // Approve User
  approveUser(userId) {
    this.userId = userId;
    this.payloadData = { userId: this.userId }
    this.userManagementService.approveUser(this.payloadData).subscribe(suc => {
      if (suc) {
        //  this.toastr.showSuccess("User Approve successfully.", "");
        this.toastr.showSuccess(suc['statusMsg'], "");
        this.rerender();
      }
    }, err => {
      this.toastr.showError(err.error.statusMsg, "");
    });
  }

  // Suspended User
  suspendUser(userId) {
    this.userId = userId;
    this.payloadData = { userId: this.userId }
    this.userManagementService.suspendedUserList(this.payloadData).subscribe(suc => {
      if (suc) {
        this.toastr.showSuccess(suc['statusMsg'], "");
        this.rerender();
      }
    }, err => {
      this.isRejectingUser = false;
      this.toastr.showError(err.error.statusMsg, "");
    });
  }

  reactiveUser(userId) {
    this.userId = userId;
    this.payloadData = { StringAsSUSPENDorREACTIVE: 'REACTIVE', userId: this.userId }
    this.userManagementService.unsuspendedUserList(this.payloadData).subscribe(suc => {
      if (suc) {
        this.toastr.showSuccess(suc['statusMsg'], "");
        this.rerender();
      }
    }, err => {
      this.toastr.showError(err.error.statusMsg, "");
    });
  }

  // Disapprove User
  disapproveUserSubmit() {
    this.userId = this.userId
    this.payloadData = { StringAsSUSPENDorREACTIVE: 'REAJECT', userId: this.userId }
    this.disapproveUserFormSubmitted = true;
    if (this.disapproveUserForm.valid) {
      let userData = JSON.parse(localStorage.getItem('userData'));
      let disapproveUserData = this.disapproveUserForm.value;
      this.userManagementService.rejectUser({ ...disapproveUserData, id: this.userId }).subscribe(suc => {
        if (suc) {
          this.disapproveUserFormSubmitted = false;
          this.disapproveUserForm.reset();
          this.toastr.showSuccess("User rejected successfully.", "");
          this.disapproveUserModalClose();
          this.rerender();
        }
      }, err => {
        this.toastr.showError(err.error.statusMsg, "");
      });
    }
  }

  // Google 2FA
  DisableGoogle2FA(userId) {
    this.userId = userId;
    this.payloadData = { userId: this.userId }
    this.userManagementService.disableGoogle2FA(this.payloadData).subscribe(suc => {
      if (suc.result == true) {
        this.toastr.showSuccess(suc['statusMsg'], "");
        this.Google2FAModel.hide();
      }
    }, err => {
      this.toastr.showError(err.error.statusMsg, "");
    });
  }

  getUserList() {
    this.dtOptions = {
      dom: 'lrtip',
      pagingType: 'full_numbers',
      pageLength: 10,
      order: [1, 'asc'],
      serverSide: true,
      processing: false,
      ajax: (dataTablesParameters: any, callback) => {
        var data = {
          page: parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1,
          pageSize: dataTablesParameters.length,
          sortOnField: dataTablesParameters.columns[dataTablesParameters.order[0].column].data,
          sortOrder: dataTablesParameters.order[0].dir.toUpperCase()
        }
        if (this.isSearch) {
          this.userList = this.searchUserList;
          this.userId = this.userId
          callback({
            recordsTotal: this.searchUserList.length,
            recordsFiltered: this.searchUserList.length,
            data: []
          });
          this.isSearch = false;
        } else {
          this.userManagementService
            .listUser(data)
            .subscribe(
              response => {
                let resData = JSON.parse(JSON.stringify(response));
                if (resData.statusCode == 200) {
                  resData.result.results.forEach(data => {
                    if(data.isAMLverified!=null && data.isAMLverified!=undefined){
                      this.UserListAML.push(data)
                    }
                  });
                  
                  this.userList = this.UserListAML;
                  callback({
                    recordsTotal: this.UserListAML.length,
                    recordsFiltered: this.UserListAML.length,
                    data: []
                  });
                } else {
                  this.userList = [];
                  callback({
                    recordsTotal: 0,
                    recordsFiltered: 0,
                    data: []
                  });
                }
              },
              error => {
                this.toastr.showError(error.error.statusMsg, "");
              }
            );
        }
      },
      columns: [
        { data: 'COUNTRY', searchable: false, orderable: false },
        { data: 'NAME', searchable: false, orderable: true },
        { data: 'EMAIL', searchable: false, orderable: true },
        { data: 'MOBILE', searchable: false, orderable: false },
        { data: 'STATUS', searchable: false, orderable: true },
        { data: 'ACTION', width: "10%", searchable: false, orderable: false }]
    };
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  viewUser(userData: any) {
    this.userDetails = userData;
    this.datauser = userData.kycDocument
    this.regulaFields = userData.regulaFields
    this.viewUserDetailsModal.show();
  }

  Google2FA(userData: any) {
    this.userDetails = userData;
    this.Google2FAModel.show();
    this.userId = this.userDetails
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  search() {
    this.isSearch = true;
    this.userManagementService
      .searchUserByMailOrPhoneOrName({ searchByMailOrPhoneOrName: this.searchText })
      .subscribe(
        response => {
          if (response.statusCode == 200) {
            this.searchUserList = response.result;
            this.rerender();
          } else {
            this.searchUserList = [];
          }
        },
        error => {
          this.toastr.showError(error.error.statusMsg, "");
        }
      );
  }

  reset() {
    this.isSearch = false;
    this.searchText = '';
    this.rerender();
  }

  confirmTabSwitch($event) {
    if (this.disableSwitching) {
      const confirm = window.confirm('Discard changes and switch tab?');
      if (confirm) {
        this.disableSwitching = false;
        this.second.active = true;
      }
    }
  }

}
