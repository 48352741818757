export const Validations =
// export const ValidationPatterns =
{
    email_pattern: /^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/, // /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,3}$/,
    phone_pattern: /^[0-9-+()]*$/,   //THIS STRING DOEST NOT ALLOW THE USER TO ENTER ANY ALPHABETICAL VALUES......
    alpha_numeric: /^[A-Z0-9]+$/i,
    alpha_numeric1: /^[\w -]*$/,
    url_pattern: /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
    onlyNum_pattern: /^-?(0|[1-9]\d*)?$/,
    price_pattern: /^[0-9]+(\.[0-9]{1,50})?$/,
    size_price_pattern: "(^[1-9.][0-9]$)|(.([^0]d[123456789\\.]))|([1-9].*[0-9])", ///^(^[1-9.][0-9]$)|(.([^0]d[123456789\\]))|([1-9].*[0-9])$/,
    decimal: /^[0-9]+(\.[0-9]{1,2})?$/,
    name_pattern: /^[a-zA-z]+$/,
    username_pattern: /^[\w.'-]+$/,
    whitespace: /^\S.*$/,
    name_space_pattern: /^[a-zA-z ]+$/,
    number_pattern: /^-?([1-9]\d*)?$/,
    phoneNumber_pattern: /^[0-9]*$/,
    us_phoneNumber_pattern: /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/,
    password_pattern: /(?=^[!@#$%\^&*()_\-+=\[{\]};:<>|\./?a-zA-Z\d]{6,}$)(?=([!@#$%\^&*()_\-+=\[{\]};:<>|\./?a-zA-Z\d]*\W+){1,})[!@#$%\^&*()_\-+=\[{\]};:<>|\./?a-zA-Z\d]*$/
}