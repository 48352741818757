import {Component, OnInit,ViewChild,ElementRef} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Validations } from '../../shared/validators/validations';
import { FormBuilder, FormControl,FormGroup,Validators} from '@angular/forms';
import { AuthService } from '../../shared/services/auth.service';
import { NotificationService } from '../../shared/services/toastr.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent implements OnInit {
	separateDialCode = false;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  forgotPasswordForm: FormGroup;
  submitted: boolean = false;
  isForgotPasswordFormSubmitted: Boolean = false;
  @ViewChild('disapproveUserModal') public disapproveUserModal: ModalDirective;
  @ViewChild('createNewpassword') public createNewpassword: ModalDirective;
  toggle1: boolean = false;
  toggle2: boolean = false;
   /** Forms required for change password */
   forgetForm: FormGroup;
   verifyEmail: FormGroup;
   verifyPhone: FormGroup;
   changeForm: FormGroup;
 
   /** Flags used for process of forget password */
   emptyVerfication = false;
   isPhoneVerified = false;
   isEmailVerified = false;

    /** To store user OTP */
  emailCode: any = null;
  phoneCode: any = null;
  phone = '';
  isVerifyEmailFormSubmitted: Boolean = false;
  isVerifyPhoneFormSubmitted: Boolean = false;
  isChangePasswordFormSubmitted: Boolean = false;
  @ViewChild("phoneNumber") phoneNumber: ElementRef;
  @ViewChild('forgotPasswordModal', { static: false }) forgotPasswordModal: ModalDirective;


  constructor(
    private router: Router,
    private titleService: Title,
    private authService: AuthService,
    private toastr: NotificationService,
    private spinner: NgxSpinnerService,
    public formBuilder: FormBuilder,
    public modalRef: BsModalRef,
  ) { }

  ngOnInit(): void {
    this.initForgetForm();
    this.initVerifyOTP();
    this.initChangePassword('', '')
  }

   /**
   * Initialize Form to get user information
   *
   * @memberof ForgetComponent
   */
    initForgetForm() {
      this.forgetForm = this.formBuilder.group({
        user: ['', [
          Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/),
          Validators.required
        ]],
        phone: ['', Validators.required]
      })
    }


    /**
   * Form to get new password from user
   *
   * @param {*} smsKey
   * @param {*} emailKey
   * @memberof ForgetComponent
   */
  initChangePassword(smsKey: any, emailKey: any) {
    this.changeForm = this.formBuilder.group({
      password: ['', [
        Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,100})/),
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(16)
      ]],
      verify: ['', Validators.required]
    })
  }

  sendForgetPassswordOTP(step: any) {
    this.isForgotPasswordFormSubmitted = true;
    if ((this.forgetForm['controls']['user'].status == 'VALID') || (this.forgetForm['controls']['phone'].status == 'VALID')) {
      let data = new URLSearchParams();
      if (this.forgetForm['controls']['user'].status == 'VALID') {
        data.set('userId', this.forgetForm.value.user);
      } else {
        data.set('userId', this.forgetForm.value.phone.e164Number.toString().replace('+', ''));
      }
      this.spinner.show();
      this.authService.sendForgetPasswordOTP(data).subscribe((res) => {
        if(res.statusCode==200){
          this.disapproveUserModal.show();
          this.initVerifyOTP();
          this.toastr.showSuccess("Verification codes has been sent successfully.", "Success");
        }
      }, (err) => {
        this.spinner.hide();
        this.toastr.showError(err.error.statusMsg, "Error");
      })
    }
  }

  initVerifyOTP() {
    let user;
    if (this.forgetForm['controls']['user'].status == 'VALID') {
      user = this.forgetForm.value.user;
    } else {
      let number = this.forgetForm.value.phone.e164Number
      user = number;
    }
    this.verifyEmail = this.formBuilder.group({
      user: [user],
      otp: ['', [Validators.required, Validators.pattern('[0-9]*'),
      Validators.minLength(6),
      Validators.maxLength(6)]],
      type: [false]
    })
    this.verifyPhone = this.formBuilder.group({
      user: [user],
      otp: ['', [Validators.required, Validators.pattern('[0-9]*'),
      Validators.minLength(6),
      Validators.maxLength(6)]],
      type: [true]
    })
  }

  /**
   * Show only Forget password process given step
   * Hide every other step
   * @param {*} step
   * @memberof ForgetComponent
   */
   showHide(step: any) {
    let els = document.getElementsByClassName('forget-step');
    for (let i = 0; i < els.length; i++) {
      els[i].classList.remove('forget-step-show');
    }
    els[step].classList.add('forget-step-show');
  }

  /**
   * Resend Forget password OTPs
   *
   * @param {*} step
   * @memberof ForgetComponent
   */
   resend(step: any) {
    this.isEmailVerified = false;
    this.isPhoneVerified = false;
    this.sendForgetPassswordOTP(step);
  }

  /**
   * Verify User entered OTPs
   *
   * @param {*} step
   * @param {*} type
   * @memberof ForgetComponent
   */
  verifyOTP(step: any, type: any) {
    if (type == 'email') {
      this.isVerifyEmailFormSubmitted = true;
    } else {
      this.isVerifyPhoneFormSubmitted = true;
    }
    if ((type == 'email' && this.verifyEmail.valid) || (type == 'phone' && this.verifyPhone.valid)) {
      this.spinner.show();
      let value = (type == 'email') ? this.verifyEmail.value : this.verifyPhone.value;
      let data = new URLSearchParams();
      data.set("userId", value.user.replace('+',''));
      data.set("otp", value.otp);
      data.set("type", value.type);
      this.authService.verifyUserOTP(data).subscribe((res) => {
        this.spinner.hide();
        if (type == 'email') {
          this.isEmailVerified = true;
          this.emailCode = res.result;
        } else {
          this.isPhoneVerified = true;
          this.phoneCode = res.result;
        }
        if (this.isEmailVerified && this.isPhoneVerified) {
          this.disapproveUserModal.hide()
          this.createNewpassword.show()
        }
        this.toastr.showSuccess("Verified successfully.", "Success");
      }, (err) => {
        this.spinner.hide();
        this.toastr.showError(err.error.statusMsg, "Error");
      })
    }
  }

  changeType(input_field_password, num){
    if(input_field_password.type=="password")
      input_field_password.type == "text";
    else
      input_field_password.type = "password";
    if(num == 1)
      this.toggle1 = !this.toggle1;
    else{
      this.toggle2 = !this.toggle2;
    }
  }

  changePassword() {
    debugger
    if (this.changeForm.valid) {
      let user;
      if (this.forgetForm['controls']['user'].status == 'VALID') {
        user = this.forgetForm.value.user;
      } else {
        user = this.forgetForm.value.phone.e164Number.toString().replace('+', '');
      }
      let data = new URLSearchParams();;
      data.set("userId",user.replace('+',''));
      data.set("smsKey", this.phoneCode);
      data.set("emailKey", this.emailCode);
      data.set("password", this.changeForm.value.password);
      this.authService.recoverPassword(data).subscribe((res) => {
        if (res.statusCode == 200) {
          localStorage.removeItem('userData');
          localStorage.removeItem('tokenData');
          this.router.navigate(['login']).then(() => {
          this.toastr.showSuccess("Password has been changed successfully.", "Success");
          });
        }
      }, (err) => {
        this.spinner.hide();
        this.toastr.showError(err.error.statusMsg, "Error");
      })
    }
  }
}

