import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { NotificationService } from '../../shared/services/toastr.service';
import { UserManagementService } from '../../shared/services/user-management.service';

@Component({
  selector: 'app-trade-restriction',
  templateUrl: './trade-restriction.component.html',
  
})
export class TradeRestrictionComponent implements AfterViewInit, OnDestroy, OnInit  {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  payloadData: any = {};
  searchPayloadData: any = {};
  userDetail: any = {};
  tableEmail: any;
  tradingFeesData :any;
  userId: any = '';
  searchText: string = '';
  isSearch: boolean = false;
  userList: Array<any>;
  searchUserList: Array<any> = [];
  searchUserListWallets: Array<any>=[];

  constructor( private toastr: NotificationService,
    private userManagementService: UserManagementService,) { }

  ngOnInit(): void {
    this.getUserList()
  }
  getUserList() {
    this.dtOptions = {
      dom: 'lrtip',
      pagingType: 'full_numbers',
      pageLength: 10,
      order: [1, 'asc'],
      serverSide: true,
      processing: false,
      ajax: (dataTablesParameters: any, callback) => {
        var data = {
          page: parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1,
          pageSize: dataTablesParameters.length,
          sortOnField: dataTablesParameters.columns[dataTablesParameters.order[0].column].data,
          sortOrder: dataTablesParameters.order[0].dir.toUpperCase()
        }
        if(this.isSearch) {
         this.userList = this.searchUserList;
          callback({
            recordsTotal: this.searchUserList.length,
            recordsFiltered: this.searchUserList.length,
            data: []
          });
          this.isSearch = false;
        } 
      },
      columns: [
        { data: 'COUNTRY', searchable: false, orderable: false },
        { data: 'NAME', searchable: false, orderable: true },
        { data: 'EMAIL', searchable: false, orderable: true },
        { data: 'MOBILE', searchable: false, orderable: false },
        { data: 'STATUS', searchable: false, orderable: true },
        { data: 'ACTION', width: "10%", searchable: false, orderable: false }]
    };
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  search() {
    this.isSearch = true;
    this.userManagementService
      .searchUserByMailOrPhoneOrName({ searchByMailOrPhoneOrName: this.searchText })
      .subscribe(
        response => {
          if (response.statusCode == 200) {
            this.searchUserList=response.result;
            this.rerender();
          } else {
            this.searchUserList = [];
          }
        },
        error => {
          this.toastr.showError(error.error.statusMsg, "");
        }
      );
  }

  reset() {
    this.isSearch = false;
    this.searchText = '';
    this.rerender();
  }

  
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  enableTradingStatus(userId){
    this.userManagementService.updateUserTradingStatusEnable(userId).subscribe(
      response => {
          this.toastr.showSuccess("Trade Enabled Successfully.", "");
          this.search();
      },
      error => {
        this.toastr.showError(error.error.statusMsg, "");
      }
    );
  }

  disableTradingStatus(userId){
    this.userManagementService.updateUserTradingStatusDisable(userId).subscribe(
      response => {
          this.toastr.showSuccess("Trade Disabled Successfully.", "");
          this.search();
      },
      error => {
        this.toastr.showError(error.error.statusMsg, "");
      }
    );
  }

  enableTradingForAll(){
    this.userManagementService.enableTradingForAll().subscribe(
      response => {
          this.toastr.showSuccess("All Trade Enabled Successfully.", "");
        
      },
      error => {
        this.toastr.showError(error.error.statusMsg, "");
      }
    );
  }

  disableTradingForAll(){
    this.userManagementService.disableTradingForAll().subscribe(
      response => {
          this.toastr.showSuccess("All Trade Disabled Successfully.", "");
          
      },
      error => {
        this.toastr.showError(error.error.statusMsg, "");
      }
    );
  }
}
