import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { data } from "jquery";

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {

  constructor(
    private http: HttpClient
  ) { }

  listUser(data){
    return this.http.get<any>(`${environment.apiUrl}admin/secure/users?page=` + data.page + `&pageSize=` + data.pageSize, data);
  }

  searchUserByMailOrPhoneOrName(parmas): Observable<any> {
    let data = {};
    return this.http.get<any>(`${environment.apiUrl}admin/secure/search?searchByMailOrPhoneOrName=` + parmas.searchByMailOrPhoneOrName, data);
  }

  verifiedUserList(data): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}admin/secure/users?page=` + data.page + `&pageSize=` + data.pageSize + `&sortOnField=` + data.sortOnField + `&sortOrder=` + data.sortOrder +`&status=APPROVED`, data);
  }

  rejectedUserList(data): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}admin/secure/users?page=` + data.page + `&pageSize=` + data.pageSize + `&sortOnField=` + data.sortOnField + `&sortOrder=` + data.sortOrder +`&status=REJECTED`, data);
  }

  registeredUserList(data): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}admin/secure/registeredUsers?page=` + data.page + `&pageSize=` + data.pageSize, data);
  }

  getPendingVerificationUserList(data): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}admin/secure/users?page=` + data.page + `&pageSize=` + data.pageSize + `&sortOnField=` + data.sortOnField + `&sortOrder=` + data.sortOrder +`&status=PENDING_BY_USER`, data);
  }
  getPendingVerifyAdminList(data): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}admin/secure/users?page=` + data.page + `&pageSize=` + data.pageSize + `&sortOnField=` + data.sortOnField + `&sortOrder=` + data.sortOrder +`&status=PENDING_BY_ADMIN`, data);
  }

  getKycSubmittedUserList(data): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}admin/secure/users?page=` + data.page + `&pageSize=` + data.pageSize + `&sortOnField=` + data.sortOnField + `&sortOrder=` + data.sortOrder +`&status=SUBMITTED`, data);
  }
  getPendingPOAList(data): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}admin/secure/users?page=` + data.page + `&pageSize=` + data.pageSize + `&sortOnField=` + data.sortOnField + `&sortOrder=` + data.sortOrder +`&status=PENDING_POA`, data);
  }

  userDetails(data): Observable<any> {
    return data;
  }

  public approveUser(data) {
    return this.http.put<any>(`${environment.apiUrl}admin/secure/user/approve/` + data.userId, data);
  }

  public rejectUser(data) {
    return this.http.put<any>(`${environment.apiUrl}admin/secure/user/reject`, data);
  }

  sendEmail(query, body): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}admin/secure/sendEmail?email=` + query, body);
  }

  sendBulkEmail(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}admin/secure/sendBulkEmail`, data);
  }

  sendSms(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}admin/secure/sendSMS?phone=` + data.phone + `&message=` + data.message, data);
  }

  sendBulkSMS(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}admin/secure/sendBulkSMS`, data);
  }

  getKYCStatus(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}admin/secure/getKycStatus`);
  }

  referralUserList(data): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}admin/secure/referral/users/1?pageSize=10`, data);
  }

  referralUserListByUserId(data: any): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}admin/secure/user/referral/` + data.userId + `/1?pageSize=1000`, data);
  }

  // suspendedAllUserList(data): Observable<any> {
  //   return this.http.get<any>(`${environment.apiUrl}admin/secure/suspendOrReactiveAllUserAccounts?StringAsSUSPENDorREACTIVE=SUSPEND&userId=`+data.userId, data);
  // }

  suspendedUserList(data): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}admin/secure/suspendOrReactiveUser/` + data.userId + `?StringAsSUSPENDorREACTIVE=SUSPEND`, data);
  }

  // unsuspendedAllUserList(data): Observable<any> {
  //   return this.http.get<any>(`${environment.apiUrl}admin/secure/suspendOrReactiveAllUserAccounts?StringAsSUSPENDorREACTIVE=REACTIVE&userId=`+data.userId, data);
  // }

  unsuspendedUserList(data): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}admin/secure/suspendOrReactiveUser/` + data.userId + `?StringAsSUSPENDorREACTIVE=REACTIVE`, data);
  }

  searchUser(data): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}admin/secure/search?searchByMailOrPhoneOrName=` + data.searchByMailOrPhoneOrName, data);
  }

  getWithdrawalFeesUsingGET(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}secure/getWithdrawalFeesUsingGET`);
  }

  updateWithdrawalFees(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}admin/secure/setWithdrawFees`, data);
  }

  getWithdrawalRevenue(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}admin/getWithdrawalRevenue?startDate=` + data.startDate + `&endDate=` + data.endDate, data);
  }

  getWithdrawalFeeDetails(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}admin/getWithdrawFeeDetails?page=` + data.page + `&pageSize=` + data.pageSize + '&startDate=' + data.startDate + `&endDate=` + data.endDate, data);
  }

  getTradeRevenue(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}admin/getTradeRevenue?startDate=` + data.startDate + `&endDate=` + data.endDate, data);
  }

  getTradeFeeDetails(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}admin/getTradingFeeDetails?page=` + data.page + `&pageSize=` + data.pageSize + '&startDate=' + data.startDate + `&endDate=` + data.endDate, data);
  }

  deleteUser(data): Observable<any> {
    return this.http.delete<any>(`${environment.apiUrl}admin/secure/user/delete?id=` + data.userId, data);
  }

  getAllWalletaddress(data): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}admin/secure/allWallet?page=` + data.page + `&pageSize=` + data.pageSize, data);
  }

  getCurrencyWalletaddress(data): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}admin/secure/getCurrencyWiseAddressDetails?page=` + data.page + `&pageSize=` + data.pageSize + `&currency=` + data.currency, data);
  }
  getRestrictionStatusMenu(data): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}admin/secure/getRestrictionStatusMenu/`, data);
  }
  getRestrictedUserList(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}admin/secure/getRestrictedUserList?page=` + data.page + `&pageSize=` + data.pageSize + `&status=` + data.status, data);
  }

  getCurrencyList(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}admin/secure/getCurrencyList`);
  }

  kycdetail(data): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}admin/secure/kyc/detail/` + data);
  }

  getUserWalletDetails(data): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}admin/secure/balance/getUserWalletDetails?userId=` + data);
  }

  updateUserWithdrawalStatusDisable(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}admin/secure/balance/updateUserWithdrawalStatus?userId=` + data + `&status=true`, data);
  }

  updateUserWithdrawalStatusEnable(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}admin/secure/balance/updateUserWithdrawalStatus?userId=` + data + `&status=false`, data);
  }

  disableWithdrawalForAll(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}admin/secure/balance/disableWithdrawalForAll`);
  }

  enableWithdrawalForAll(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}admin/secure/balance/enableWithdrawalForAll`);
  }

  updateUserTradingStatusDisable(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}admin/secure/balance/updateUserTradingStatus?userId=` + data + `&status=false`, data);
  }

  updateUserTradingStatusEnable(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}admin/secure/balance/updateUserTradingStatus?userId=` + data + `&status=true`, data);
  }
  disableTradingForAll(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}admin/secure/balance/disableTradingForAll`);
  }
  enableTradingForAll(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}admin/secure/balance/enableTradingForAll`);
  }
  disableGoogle2FA(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}admin/secure/disableGoogle2FA?userId=` + data.userId, data);
  }
  getDisabledCountryList(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}admin/secure/getDisabledCountryList`);
  }

  // Data Controller method (API)
  getCountryList(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}admin/secure/getCountryList`);
  }

  setDisabledCountries(data): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}admin/secure/setDisabledCountries?disabledCountry=` + data, data);
  }
  enableProofsManually(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}admin/secure/verifyProofsManually/?userId=` +  data.userId + `&proofName=`+data.proofName+`&status=true`, data);
  }
  disableProofsManually(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}admin/secure/verifyProofsManually/?userId=` +  data.userId + `&proofName=`+data.proofName+`&status=false`, data);
  }
  manualAMLScan(data) {
    return this.http.post<any>(`${environment.apiUrl}admin/secure/manualAMLScan?userId=` + data.userId.id, data);
  }

}
