import { Component } from '@angular/core';
import { navItems } from '../../_nav';
import { Router } from '@angular/router';

import { AuthService } from '../../shared/services/auth.service';
import { NotificationService } from '../../shared/services/toastr.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent {

  // public sidebarMinimized = false;
  public sidebarMinimized = true;
  public navItems = navItems;
  profilePicture: any;
  currentYear: any = new Date().getFullYear();
  userName: any;

  constructor(
    private authService: AuthService,
    private toastr: NotificationService,
    private router: Router
  ) { }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  ngOnInit() {
    this.userName = JSON.parse(localStorage.getItem('userData'))['firstName']
  }

  logout() {
    localStorage.removeItem('userData');
    localStorage.removeItem('tokenData');
    this.router.navigate(['login']).then(() => {
    this.toastr.showSuccess("Logout Successfully.", "");
    });

    // this.authService.logout("").subscribe(response => {
    //   let resData = JSON.parse(JSON.stringify(response));
    //   if (resData.status == 200) {
    //     //localStorage.clear();
    //     localStorage.removeItem('user_details');
    //     this.router.navigate(['login']).then(() => {
    //       this.toastr.showSuccess(response.message, "");
    //     });
    //   } else {
    //     this.toastr.showError(response.message, "");
    //   }
    // },
    //   err => {
    //     this.toastr.showError(err.error.message, "");
    //   });
  }

  onImgError(event) {
    event.target.src = 'assets/img/brand/user_placeholder.png';
  }

}
