import {
  Injectable,
  OnInit
} from "@angular/core";
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class AuthService implements OnInit {

  headers;
  cache: Object = null;
  token: String = "";

  constructor(
    private http: HttpClient
  ) {
    this.headers = new HttpHeaders();
  }

  ngOnInit() { }

  getAdminDetails() {
    return JSON.parse(localStorage.getItem("userData"));
  }

  login(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}main/user/login`, data);
  }

  forgotPassword(data): Observable<any> {
    let options = this.getRequestOption(true);
    return this.http.post<any>(`${environment.apiUrl}main/forget`, data.toString(), options);
  }

  resetPassword(data): Observable<any> {
    // return this.http.put<any>(`${environment.api_url}/auth/resetPassword`, data);
    return data;
  }

  logout(data): Observable<any> {
    // return this.http.post<any>(`${environment.api_url}/api/logout`, data);
    return data;
  }

  public changePassword(data: any) {
    return this.http.post<any>(`${environment.apiUrl}main/user/changePass?newPassword=` + data.newPassword + `&oldPassword=` + data.oldPassword, data);
  }

  /**
     * Change user password
     *
     * @param {*} params
     * @returns
     * @memberof ForgetService
     */
  recoverPassword(data: any) {
    let options = this.getRequestOption(true);
    return this.http.post<any>(`${environment.apiUrl}main/recoverPass`, data.toString(), options);
  }

  private getRequestOption(formEncoded: boolean) {
    let headers: HttpHeaders = this.headers;
    if (formEncoded == true) {
      headers = headers.set("Content-type", "application/x-www-form-urlencoded");
    } else if (formEncoded == false) {
      headers = headers.set("Content-type", "application/json");
    }
    return {
      headers: headers,
    };
  }

  // Get Approved and Disapproved user count
  public getDifferentUserCount() {
    // return this.httpService.get<any>(`${this._baseUrl}/users/differentUsersCounts`, null, false);
    return this.http.get<any>(`${environment.apiUrl}admin/secure/users/differentUsersCounts`);
  }

  public getTotalUserCount() {
    return this.http.get<any>(`${environment.apiUrl}admin/secure/users/count`);
  }

  getWithdrawalFees() {
    return this.http.get<any>(`${environment.apiUrl}admin/secure/getWithdrawalFees`);
  }

  public getWithdrawalRevenue(data) {
    return this.http.get<any>(`${environment.apiUrl}admin/secure/getWithdrawalRevenue?startDate` + data.startDate + `?endDate=` + data.endDate, data);
  }

  public async getIPAddress() {
    const response = await fetch(environment.ipifyUrl);
    const data = await response.json();
    return data;
  }

  /**
  * Send Forget password OTPs to given user
  * information
  * @param {*} params
  * @returns
  * @memberof ForgetService
  */
  sendForgetPasswordOTP(data: any) {
    let options = this.getRequestOption(true);
    return this.http.post<any>(`${environment.apiUrl}main/forget`, data.toString(), options);
  }

  /**
     * Verify User entered OTPs
     *
     * @param {*} params
     * @returns
     * @memberof ForgetService
     */
  verifyUserOTP(data: any) {
    let options = this.getRequestOption(true);
    return this.http.post<any>(`${environment.apiUrl}main/verify`, data.toString(), options);
  }

  verifyGoogleCode(data: any) {
    return this.http.post<any>(`${environment.apiUrl}main/user/verifyGoogleCode`, data);
  }

  setAuthUser(userData: any) {
    localStorage.setItem('userData', JSON.stringify(userData))
  }

  setUserData(userData: any) {
    localStorage.setItem('userData', JSON.stringify(userData))
  }
}
