import {Component, OnInit,ViewChild} from '@angular/core';
import { FormGroup,Validators, FormControl, FormBuilder} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Validations } from '../../shared/validators/validations';
import { AuthService } from '../../shared/services/auth.service';
import { NotificationService } from '../../shared/services/toastr.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { DataService } from '../../shared/services/data.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})

export class LoginComponent implements OnInit {
  loginData: any;
  loginForm: FormGroup;
  isFormSubmitted: boolean = false;
  google2FAAuthenticationForm: FormGroup;
  @ViewChild('google2FAAuthenticationModal', { static: false }) google2FAAuthenticationModal: ModalDirective;
  isGoogle2FAFormSubmitted: boolean = false;
  userData: any;
  constructor(
    private router: Router,
    private titleService: Title,
    private authService: AuthService,
    private toastr: NotificationService,
    private spinner: NgxSpinnerService,
    public formBuilder: FormBuilder,
    private data: DataService,
  ) { }

  ngOnInit(): void {
    this.getIP();
    this.titleService.setTitle('Login');
    this.loginForm = this.formBuilder.group({
      email: new FormControl('', [Validators.required,Validators.pattern(Validations.whitespace),Validators.pattern(Validations.email_pattern)]),
      password: new FormControl('', [Validators.required,Validators.minLength(6),Validators.pattern(Validations.whitespace)]),
    });
    this.google2FAAuthenticationForm = this.formBuilder.group({
      google2FACode: new FormControl('', [ Validators.required]),
    })
    let adminData = JSON.parse(localStorage.getItem('_logindetails'));
    if (adminData) {
      this.router.navigate(['/dashboard']);
    }
  }
  ipAddress: string;

  getIP() {
    this.authService.getIPAddress().then(res => {
      this.ipAddress = res.ip;
    });
  }

  submitLoginForm(valid: any) {
    this.isFormSubmitted = true;
    if (this.loginForm.valid) {
      this.spinner.show();
      let formData = new FormData();
      formData.append('user', this.loginForm.value.email.toLowerCase());
      formData.append('password', this.loginForm.value.password);
      formData.append('ip', this.ipAddress);
      this.authService.login(formData).subscribe(response => {
        this.spinner.hide();
        let resData = JSON.parse(JSON.stringify(response));
        if (resData.statusCode == 200) {
          let userData = resData.result.user;
          let tokenData = resData.result.token;
          this.authService.setUserData(userData);
          this.userData = resData.result.user;
          localStorage.setItem('userData', JSON.stringify(userData));
          localStorage.setItem('tokenData', JSON.stringify(tokenData));
          let google2FAVerified = userData['2FAEnabled'];
          if(google2FAVerified){
            this.isGoogle2FAFormSubmitted = false;
            this.google2FAAuthenticationForm.reset();
            this.google2FAAuthenticationModal.show();
          }else {
          this.userData = resData.result;
          this.data.changeLoginStatus(true);
          this.router.navigate(['/dashboard']);
          }
        } else {
          this.toastr.showError(resData.statusMsg, "");
          this.spinner.hide();
        }
      },
        err => {
          this.toastr.showError(err.error.statusMsg, "");
          this.spinner.hide();
        });
    }
  }

  google2FAVerification() {
    this.isGoogle2FAFormSubmitted = true;
    if (this.google2FAAuthenticationForm.valid) {
      this.spinner.show();
      let formData = new FormData();
      formData.append('code', this.google2FAAuthenticationForm.value.google2FACode);
      this.authService.verifyGoogleCode(formData).subscribe(data => {
        this.spinner.hide();
        if (data.result) {
          this.google2FAAuthenticationModal.hide();
          this.authService.setAuthUser(this.userData)
          this.data.changeLoginStatus(true);
          this.router.navigate(['/dashboard']);
        } else {
          this.spinner.hide();
          this.toastr.showError("Invalid Google 2FA Code.", "");
        }
      },
        err => {
          this.spinner.hide();
          this.toastr.showError(err.error.statusMsg, "");
        })
    }
  }
}
