import { Injectable } from '@angular/core';
import {
    CanActivate,
    Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';

import { AuthService } from '../services/auth.service';

@Injectable()
export class UserAuth implements CanActivate {
    perm: any;

    constructor(
        private router: Router,
        private authService: AuthService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.authService.getAdminDetails()) {
            return true;
        } else {
            this.router.navigate(['/login']);
            return false;
        }
    }

    canLoad() {
        if (this.authService.getAdminDetails() && this.perm.length) {
            return true;
        } else {
            this.router.navigate(['/login']);
            return false;
        }
    }
}